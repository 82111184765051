import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const seedlingsDataSlice = createSlice({
  name: "seedlingsData",
  initialState,
  reducers: {
    setData: (state, action) => {
      console.log(action.payload);
    //   state.value = [action.payload, ...state.value];
      console.log("state", action);
    },
  },
});

export const { setData } = seedlingsDataSlice.actions;

export default seedlingsDataSlice.reducer;
