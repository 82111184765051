import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const korzinaSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    korzinaAdd: (state, action) => {
      console.log(action.payload);
      state.value = [action.payload, ...state.value];
      console.log("state", action);
    },
    korzinaDelete: (state, action) => {
      state.value = state.value?.filter((i) => i?.id != action?.payload?.id);
    },
  },
});

export const { korzinaAdd,korzinaDelete } = korzinaSlice.actions;

export default korzinaSlice.reducer;
