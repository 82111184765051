import React from "react";
import loading from '../../assets/images/loading/1.gif'
import loading1 from "../../assets/images/loading/2.gif";

export const Loading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <img style={{width: "250px"}} src={loading} alt="loading" />
    </div>
  );
};
